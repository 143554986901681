import React, { useState } from "react"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, MapPageQuery, RegionNetworksFieldsFragment } from "../../../../graphql-types"
import Layout from "../../components/layout/layout"
import * as styles from "./map.module.scss"
import { srOnly } from "../../../global.module.scss"
import { useIsMobile, useSlugValue } from "../../hooks"
import Filters from "./filters/filters"
import GeographicFilters from "./filters/geographic-filters"
import GoogleMapWrapper from "./google-map-wrapper/google-map-wrapper"
import NetworkDetails from "./network-details/network-details"
import RightArrow from "../../../../static/vectors/v3/right-arrow.svg"
import { ReactSVG } from "react-svg"

type RenderProps = {
  data: MapPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
  }
}

export type TransportModes = {
  name: string
  isSelected: boolean
}

export type NetworkDetailsLabels = {
  selectAllLabel: string
  seeMoreLabel: string
  seeLessLabel: string
  institLinkLabel: string
  recruitmentLinkLabel: string
  socialNetworksLabel: string
  subsidiarySitesLabel: string
}

const Map: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const mapData = data.datoCmsMapPage
  const transportModes = data.allDatoCmsKeolisTransportMode.nodes
  const [transportModesSelected, setTransportModesSelected] = useState<TransportModes[]>(
    transportModes.map(mode => ({ name: mode.transportModeName, isSelected: true }))
  )

  const labels: NetworkDetailsLabels = {
    selectAllLabel: mapData.labelSelectAll,
    seeMoreLabel: mapData.seeMoreLabel,
    seeLessLabel: mapData.seeLessLabel,
    institLinkLabel: mapData.institLinkLabel,
    recruitmentLinkLabel: mapData.recruitmentLinkLabel,
    socialNetworksLabel: mapData.socialNetworksLabel,
    subsidiarySitesLabel: mapData.subsidiarySitesLabel,
  }

  const [focus, setFocus] = useState(null)
  const [selectedNetwork, setSelectedNetwork] = useState<RegionNetworksFieldsFragment>(null)
  const [selectedSite, setSelectedSite] = useState(null)
  const [isGeographicFilterOpen, setIsGeographicFilterOpen] = useState(false)

  const isMobile = useIsMobile()
  const seeLessCount = 3

  const handleClick = (name: string) => {
    if (name === "") {
      setTransportModesSelected(transportModes.map(mode => ({ name: mode.transportModeName, isSelected: true })))
    } else {
      const allFiltersSelected = transportModesSelected.filter(item => item.isSelected === false).length === 0
      const updatedList = transportModesSelected.map(item => {
        if (item.name === name) {
          return { ...item, isSelected: allFiltersSelected ? true : !item.isSelected }
        } else if (allFiltersSelected) {
          return { ...item, isSelected: false }
        }
        return item
      })
      setTransportModesSelected(updatedList)
    }
  }

  const handleFocusedNetwork = (
    focus: { zoom: number; lat: number; lng: number },
    network?: RegionNetworksFieldsFragment
  ) => {
    setSelectedSite(null)
    setFocus(focus)
    setSelectedNetwork(network)
  }

  const handleSubsidiarySiteClick = (focus: { zoom: number; lat: number; lng: number }, site: any) => {
    setFocus(focus)
    setSelectedSite(site)
  }

  const handleBackClick = () => {
    setSelectedNetwork(null)
    setSelectedSite(null)
    setFocus({ zoom: 3, lat: focus.lat, lng: focus.lng })
  }

  const getTransportModesNames = (transportModes: TransportModes[]) =>
    transportModes.filter(item => item.isSelected === true).map(item => item.name)

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={useSlugValue(pageContext.slugs._allMapPageSlugLocales, pageContext.locale)}
      pageTemplate="map"
    >
      <div className={`${styles.mapPageContainer} ${mapData.isBackgroundDisplayed ? styles.background : ""}`}>
        {isMobile ? (
          <>
            <h2 className={styles.title}>{mapData.title}</h2>
            <h2 className={srOnly}>{mapData.cartographyTitleAccessibilty}</h2>
            {/* <div>search bar</div> */}
            <div className={styles.transportModeFilters}>
              {mapData.isFilterEnabled && (
                <Filters
                  allTransportModes={transportModes}
                  transportModesSelected={transportModesSelected}
                  labels={labels}
                  seeLessCount={seeLessCount}
                  onClick={handleClick}
                />
              )}
            </div>

            <GoogleMapWrapper
              data={data.datoCmsMapPage}
              allTransportNetworks={data.allDatoCmsTransportNetwork.nodes}
              transportModesSelected={getTransportModesNames(transportModesSelected)}
              onNetworkClick={handleFocusedNetwork}
              focus={focus}
              selectedNetwork={selectedNetwork}
              selectedSite={selectedSite}
            />
            <div className={`${styles.leftContentContainer} ${isGeographicFilterOpen ? styles.open : styles.closed}`}>
              {selectedNetwork && (
                <NetworkDetails
                  network={selectedNetwork}
                  labels={labels}
                  onBackClick={handleBackClick}
                  onNetworkClick={handleSubsidiarySiteClick}
                />
              )}
              <div className={selectedNetwork ? styles.hidden : ""}>
                <div onClick={() => setIsGeographicFilterOpen(!isGeographicFilterOpen)}>
                  <div
                    className={`${styles.toggleArrow} ${isGeographicFilterOpen ? styles.arrowUp : styles.arrowDown}`}
                  >
                    <ReactSVG src={RightArrow} />
                  </div>
                  <div className={`${styles.subtitle} `}>{mapData.subtitle}</div>
                </div>
                {/* <div className={styles.searchBar}>Saisir une ville</div> */}
                {isGeographicFilterOpen && (
                  <GeographicFilters
                    allTransportNetworks={data.allDatoCmsTransportNetwork.nodes}
                    onNetworkClick={handleFocusedNetwork}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.header}>
              <div className={styles.leftContent}>
                <h1 className={styles.title}>{mapData.title}</h1>
                <h2 className={srOnly}>{mapData.cartographyTitleAccessibilty}</h2>
              </div>
              <div className={styles.rightContent}>
                <span className={styles.introText}>{mapData.chapo}</span>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.leftContent}>
                <div className={styles.leftContentContainer}>
                  {selectedNetwork && (
                    <NetworkDetails
                      network={selectedNetwork}
                      labels={labels}
                      onBackClick={handleBackClick}
                      onNetworkClick={handleSubsidiarySiteClick}
                    />
                  )}
                  <div className={selectedNetwork ? styles.hidden : ""}>
                    <div className={styles.subtitle}>{mapData.subtitle}</div>
                    {/* <div className={styles.searchBar}>Saisir une ville</div> */}
                    <GeographicFilters
                      allTransportNetworks={data.allDatoCmsTransportNetwork.nodes}
                      onNetworkClick={handleFocusedNetwork}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.rightContent}>
                <div className={styles.mapContainer}>
                  <GoogleMapWrapper
                    data={data.datoCmsMapPage}
                    allTransportNetworks={data.allDatoCmsTransportNetwork.nodes}
                    transportModesSelected={getTransportModesNames(transportModesSelected)}
                    onNetworkClick={handleFocusedNetwork}
                    focus={focus}
                    selectedNetwork={selectedNetwork}
                    selectedSite={selectedSite}
                  />

                  <div className={styles.transportModeFilters}>
                    {mapData.isFilterEnabled && (
                      <Filters
                        allTransportModes={transportModes}
                        transportModesSelected={transportModesSelected}
                        labels={labels}
                        seeLessCount={seeLessCount}
                        onClick={handleClick}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}
export const fragment = graphql`
  fragment MapPageFields on DatoCmsMapPage {
    isNetworkEnabled
    isFilterEnabled
    title
    subtitle
    chapo
    isBackgroundDisplayed
    blockColor {
      hex
    }
    cartographyTitleAccessibilty
    labelSelectAll
    seeMoreLabel
    seeLessLabel
    institLinkLabel
    recruitmentLinkLabel
    socialNetworksLabel
    subsidiarySitesLabel
    defaultZoom
    defaultPosition {
      latitude
      longitude
    }
    iconMarkerClusterer {
      url
    }
    displayAdministrativeProvinces
    displayLocalityNames
    displayHighways
    displayArterialRoads
  }
`
export const pageQuery = graphql`
  query MapPage($locale: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }

    datoCmsMapPage(locale: $locale) {
      ...MapPageFields
    }
    allDatoCmsKeolisTransportMode(locale: $locale, sort: { position: ASC }) {
      nodes {
        ... on DatoCmsKeolisTransportMode {
          ...AllTransportModesFields
        }
      }
    }
    allDatoCmsTransportNetwork(locale: $locale) {
      nodes {
        ...RegionNetworksFields # this comes from old map.tsx, which need to be moved here once v3 is released
      }
    }
  }
`

export default Map
