import React, { Fragment, useEffect, useState } from "react"
import { AllTransportModesFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./filters.module.scss"
import { useIsMobile } from "../../../../hooks"
import { TransportModes } from "../map"
import { DisplayImage } from "../../../../components/display-image/display-image"
import { NetworkDetailsLabels } from "../map"

type RenderProps = {
  allTransportModes: AllTransportModesFieldsFragment[]
  transportModesSelected: TransportModes[]
  // labelSelectAll: string
  labels: NetworkDetailsLabels
  seeLessCount: number
  onClick: (value: string) => void
}

const Filters: React.FC<RenderProps> = ({
  allTransportModes,
  transportModesSelected,
  labels,
  seeLessCount,
  onClick,
}) => {
  const isMobile = useIsMobile()
  const [seeLess, setSeeLess] = useState(true)
  const handleSeeLess = () => {
    setSeeLess(!seeLess)
  }
  const labelSelectAllMobile = seeLess ? labels.seeMoreLabel : labels.seeLessLabel
  const areAllTransportModeSelected =
    transportModesSelected.filter(transportMode => transportMode.isSelected === true).length ===
    allTransportModes.length

  return (
    <ul className={styles.allTransportModes}>
      {allTransportModes.map((mode, index) => {
        const isHidden = isMobile && seeLess && index >= seeLessCount
        return (
          <Fragment key={index}>
            <li
              className={`${styles.transportMode} ${isHidden ? styles.transportModeHidden : ""} ${transportModesSelected[index].isSelected ? styles.transportModeSelected : ""}`}
              onClick={() => onClick(mode.transportModeName)}
            >
              <DisplayImage image={mode.transportModeIcon} svgStyle={styles.pictogram} imgStyle={styles.pictogram} />
              {isMobile && <span>{mode.transportModeName}</span>}
            </li>
          </Fragment>
        )
      })}

      <li className={styles.selectAll} onClick={isMobile ? handleSeeLess : () => onClick("")}>
        <span>{isMobile ? labelSelectAllMobile : labels.selectAllLabel}</span>
      </li>
    </ul>
  )
}

export default Filters
