import React, { useState } from "react"
import { ReactSVG } from "react-svg"
import * as styles from "./network-details.module.scss"
import { DisplayImage } from "../../../../components/display-image/display-image"
import { RegionNetworksFieldsFragment } from "../../../../../graphql-types"
import { useSlugs } from "../../../context/slugs-context"
import { NetworkDetailsLabels } from "../map"

type RenderProps = {
  network: RegionNetworksFieldsFragment
  labels: NetworkDetailsLabels
  onBackClick: () => void
  onNetworkClick: (focus: { zoom: number; lat: number; lng: number }, network: RegionNetworksFieldsFragment) => void
}

const DisplayLink = ({
  label,
  link,
  textLink,
  target,
}: {
  label: string
  link: string
  textLink?: string
  target: string
}) => {
  return (
    <div className={styles.label}>
      {label}
      <span className={styles.alignRight}>
        <a href={link} target={target}>
          {textLink || link}
        </a>
      </span>
    </div>
  )
}

const NetworkDetails: React.FC<RenderProps> = ({ network, labels, onBackClick, onNetworkClick }) => {
  const { getInternalExternalLink } = useSlugs()
  const [activeSite, setActiveSite] = useState(0)

  return (
    <>
      <button className={styles.backButton} onClick={onBackClick}>
        <span className={styles.backBracket}>{"<"} </span>Retour
      </button>
      <div className={styles.networkName}>{network.subsidiaryName || network.networkName}</div>
      <div className={styles.transportModes}>
        {network.networkTransportModes.map((mode, index) => (
          <div key={index} className={styles.transportMode}>
            <DisplayImage image={mode.transportModeIcon} svgStyle={styles.pictogram} imgStyle={styles.pictogram} />
          </div>
        ))}
      </div>
      <div className={styles.networkLinks}>
        {network.institRedirectLink && (
          <DisplayLink
            link={getInternalExternalLink(network.institRedirectLink)}
            label={labels.institLinkLabel}
            textLink={network.institRedirectLink.title || "lien"}
            target={network.institRedirectLink.isInternalPage ? "_self" : "_blank"}
          />
        )}
        {network.recruitmentRedirectLink && (
          <DisplayLink
            link={getInternalExternalLink(network.recruitmentRedirectLink)}
            label={labels.recruitmentLinkLabel}
            textLink={network.recruitmentRedirectLink.title || "lien"}
            target={network.recruitmentRedirectLink.isInternalPage ? "_self" : "_blank"}
          />
        )}
        {network.socialNetworks && (
          <div className={styles.socialNetworks}>
            <div className={styles.label}>{labels.socialNetworksLabel}</div>
            <ul className={styles.socialNetworksList}>
              {network.socialNetworks.map((socialNetwork, index) => {
                return (
                  <li key={index}>
                    <a href={socialNetwork.link} target="_blank" className={styles.socialNetwork}>
                      <ReactSVG src={socialNetwork.image.url} className={styles.icon} />
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
      {network.subsidiaryName && network.otherSites && (
        <>
          <div className={styles.networkName}>{labels.subsidiarySitesLabel}</div>
          <ul className={styles.otherSites}>
            {network.otherSites.map((site, index) => (
              <li
                key={index}
                className={index === activeSite ? styles.activeSite : styles.site}
                onClick={() => {
                  setActiveSite(index)
                  onNetworkClick(
                    {
                      zoom: 12,
                      lat: site.networkLocation.latitude,
                      lng: site.networkLocation.longitude,
                    },
                    site
                  )
                }}
              >
                {site.networkName}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  )
}

export default NetworkDetails
