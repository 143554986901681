import { GoogleMap, useJsApiLoader } from "@react-google-maps/api"

import React, { useEffect, useRef } from "react"
import { NetworkFieldsFragment, RegionNetworksFieldsFragment } from "../../../../../graphql-types"
import {
  DEFAULT_MAP_PARAMETERS,
  GOOGLE_MAP_API_PARAMETERS,
  MAP_CONTAINER_STYLE,
  MAP_CUSTOM_STYLE,
} from "../../../../constant"
import { useIsMobile } from "../../../../hooks"
import * as styles from "./google-map-wrapper.module.scss"

import MarkersFiltered from "./markers-filtered/markers-filtered"

type RenderProps = {
  data: NetworkFieldsFragment
  allTransportNetworks: RegionNetworksFieldsFragment[]
  transportModesSelected: string[]
  onNetworkClick: (focus, network?) => void
  focus: { zoom: number; lat: number; lng: number }
  selectedNetwork: RegionNetworksFieldsFragment
  selectedSite: any
}

const GoogleMapWrapper: React.FC<RenderProps> = ({
  data,
  allTransportNetworks,
  transportModesSelected,
  onNetworkClick,
  focus,
  selectedNetwork,
  selectedSite,
}) => {
  const isMobile = useIsMobile()
  const { isLoaded } = useJsApiLoader(GOOGLE_MAP_API_PARAMETERS)

  const center = {
    lat: data.defaultPosition?.latitude || DEFAULT_MAP_PARAMETERS.latitude,
    lng: data.defaultPosition?.longitude || DEFAULT_MAP_PARAMETERS.longitude,
  }
  let zoom = focus ? focus.zoom : isMobile ? data.defaultZoom + 0.5 : data.defaultZoom

  const filteredNetworksByTransportModes = allTransportNetworks.filter(element => {
    let isNetworkContainingTransportMode = false
    element.networkTransportModes.forEach(x => {
      if (transportModesSelected.includes(x.transportModeName)) {
        isNetworkContainingTransportMode = true
      }
    })
    return isNetworkContainingTransportMode && element
  })
  const mapRef = useRef(null)
  const onLoad = map => {
    mapRef.current = map
  }
  useEffect(() => {
    if (mapRef.current && focus) {
      mapRef.current.panTo(focus)
    }
  }, [focus])

  let customStyles = []
  if (data.displayAdministrativeProvinces) {
    customStyles.push({
      featureType: "administrative.province",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    })
  }
  if (data.displayLocalityNames) {
    customStyles.push({
      featureType: "administrative.locality",
      elementType: "labels",
      stylers: [{ visibility: "on" }],
    })
  }
  if (data.displayHighways) {
    customStyles.push({
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#c0c0c0", visibility: "on" }],
    })
  }
  if (data.displayArterialRoads) {
    customStyles.push({
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [{ color: "#d3d3d3" }],
    })
  }

  return (
    isLoaded && (
      <div className={styles.googleMapWrapper}>
        <GoogleMap
          mapContainerStyle={MAP_CONTAINER_STYLE}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          options={{
            styles: MAP_CUSTOM_STYLE.concat(customStyles),
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            draggableCursor: "grab",
            zoom,
            zoomControl: true,
            zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_CENTER },
            maxZoom: 20,
            minZoom: isMobile ? 1 : 3,
            restriction: { latLngBounds: { north: 83.8, south: -57, west: -170, east: 180 } },
          }}
        >
          <MarkersFiltered
            transportNetworks={filteredNetworksByTransportModes}
            urlIconMarkerClusterer={data.iconMarkerClusterer.url}
            onClick={onNetworkClick}
            selectedNetwork={selectedNetwork}
            selectedSite={selectedSite}
          />
        </GoogleMap>
      </div>
    )
  )
}

export default GoogleMapWrapper
